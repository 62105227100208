function makeSchema() {
    return {
        '@context': 'http://schema.org',
        '@type': 'Corporation',
        name: 'Viome',
        url: 'https://www.viome.com/',
        logo: 'https://images.ctfassets.net/qk4l4jfatr3e/1SMJ6OQPL8kTNsze9cXpzS/a0d3137c8503f29d9caabd319a566db8/viome-dark-logo.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '+1-855-958-4663',
            contactType: 'customer service',
            contactOption: 'TollFree',
        },
        sameAs: [
            'https://www.facebook.com/MyViome',
            'https://twitter.com/myviome',
            'https://www.youtube.com/viome',
            'https://www.linkedin.com/company/viome/',
            'https://www.instagram.com/viome/',
        ],
    };
}

export default function Schema() {
    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(makeSchema()),
            }}
        />
    );
}
